import React, { useMemo ,useState,useEffect } from "react";
import Card from "components/card";
import "../../../../src/assets/css/App.css"
import Dropdown from "components/dropdown";
import { MdLastPage } from "react-icons/md";
import { MdFirstPage } from "react-icons/md";
import { MdNavigateNext } from "react-icons/md";
import { MdNavigateBefore } from "react-icons/md";
import api from "views/auth/Api";
import { columnsDataCheck } from "./variables/columnsData";
import { FaFileExport } from "react-icons/fa";
import { API_BASE_URL } from '../../../configs/AppConfig';

// import { CSVLink } from 'react-csv'
// import { BsThreeDots } from "react-icons/bs";
// import { MdEdit } from "react-icons/md";
// import { FaRegTrashAlt } from "react-icons/fa";
// import { RiAddBoxFill } from "react-icons/ri";
// import { Formik, Form, Field, ErrorMessage } from 'formik';
// import { FaRegWindowClose } from "react-icons/fa";
// import { message } from 'antd';


import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";


const Tables = () => {
  const [isLoading , setIsLoading] = useState(false);
  // const [isOpen, setIsOpen] = useState(false);
  // const [editData , setEditData] = useState();
  // const [delOpen, setDelOpen] = useState(false);
  // const csvLink = useRef()
  // const [transactionData, setTransactionData] = useState([])
  // const [open, setOpen] = useState(false);
  // const [id , setId] = useState("");


// ***************************** Massages *****************************
  // const success = () => {
  //   message.success('done');
  // };

  // const errorM = () => {
  //   message.error('This is a message of error');
  // };
  
  // const delSuccess = ()=>{
  //   message.success('Delete done');
  // }
// **********************************************************
// ***************************** Delete Statistic *****************************

// const deleteData = (id) => {
//   setIsOpen(false);
//   if (id) {
//     api.delete(`http://localhost:3001/statistics/${id}`)
//       .then(function (response) {
//         console.log(response, "response{{}}}{{")
//         api.get("http://localhost:3001/statistics/")
//         .then(function (res) {
//           setTlData(res.data)
//           delSuccess()
//           console.log(res, "res{{}}}{{")

//         }).catch(error => {
//           console.log("handlesubmit error for blog ", error)
//         });
//       }).catch(error => {
//         console.log("handlesubmit error for blog ", error)
//       });

//   }
// }
    // **********************************************************
  
    // this jsx code for download csv file

     /* <CSVLink
        data={transactionData}
        filename='transactions.csv'
        className='hidden'
        ref={csvLink}
        target='_blank' /> */


// ***************************** Export Reqest *****************************
    const  exportReq = () =>{
      const accessToken = localStorage.getItem('token');
     window.open(`${API_BASE_URL}users/export?token=${accessToken}`,
      '_blank',
      'noopener,noreferrer'
    );

   };
    
  const usersExport= ()=> {
    return ( 
      <Dropdown
        button={
          <button
            className={`flex items-center text-xl hover:cursor-pointer bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10
              } linear justify-center rounded-lg font-bold transition duration-200 MDaddStatistic`}
          >  
            <FaFileExport className="h-6 w-6"/>
          </button>
        }
        />
      ) 
  }

  // **********************************************************

// ***************************** Edit Statistic *****************************

  // const statisticsButton= (id)=>{
  //   const editStatistic= (id)=> {
  //     if (!isOpen) {
  //       setIsOpen(true)
  //   }      
  //     const ddata = [...tlData]
  //     const perData = ddata.filter((per) => per.id ===id)
  //     setEditData(perData[0]);
  //   }

  //   // **********************************************************

  //   // ***************************** Dropdown edit and  delete *****************************

  //   return (
  //     <Dropdown
  //       button={
  //         <button
  //           onClick={() => {setOpen(!open);
  //             setDelOpen(false);
  //             setIsOpen(false);
  //           } }
  //           open={open}
  //           className={` flex items-center text-xl hover:cursor-pointer bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10
  //              linear justify-center rounded-lg font-bold transition duration-200 `}
  //         >
            
  //           <BsThreeDots className="h-4 w-4" />
  //         </button>
  //       }
  //       animation={"origin-top-right transition-all duration-300 ease-in-out"}
  //       classNames="top-8 right-0 w-max "
  //       children={
  //         <div className= {`z-50 w-max rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none`} >
  //           <p className="hover:text-black flex cursor-pointer items-center gap-2 text-gray-600 hover:font-medium" onClick={() => editStatistic(id)}>
  //             <span>
  //               <MdEdit />
  //             </span>
  //             Edit
  //           </p>
            
  //            <p className="hover:text-black mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium" onClick={()=>{setDelOpen(true)}}>
  //             <span>
  //               <FaRegTrashAlt />
  //             </span>
  //             Delete
  //           </p>           
  //         </div>
  //       }
  //     />
  //   );
  // }
  
  // const rowId =(id)=>{
  //   console.log(id,"id")
  //   setId(id)
  // }    
  // **********************************************************

  // ***************************** Get all Statistic *****************************
  
  const [tlData , setTlData] = useState([]);
  
  const getData = async()=>{
    setIsLoading(true);
    api.get(`${API_BASE_URL}users`)
  .then(function (response) {
    setTlData(response.data)
    setIsLoading(false);
  }).catch(error => {
    console.log("handlesubmit error for blog get ", error)
    localStorage.clear()
    window.location.reload();
  });
}
  useEffect(()=>{ getData()},[]);
  const  columnsData = columnsDataCheck;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tlData, [tlData]);


    // **********************************************************

// ***************************** useTable *****************************

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 15 }

    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    // initialState,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    // setPageSize,
    // setGlobalFilter,
    state: { pageIndex }
    // state: { pageIndex, pageSize }


  } = tableInstance;
  // initialState.pageSize = 10;


//************************************************************

// ***************************** return Table *****************************

  return (
    <div id="usersTable">
    <Card extra={"w-full h-full sm:overflow-auto px-6 pb-4"}>
      <header className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white w-auto	flex flex-row justify-center items-center">
         <span className="mr-5 MDtitle"> Users </span>

        </div>

        {/* <CardMenu /> */}
        <div id="addStatistic" onClick={() => exportReq()} >{usersExport()}</div>
        {/* <div id="addStatistic" onClick={() => addOne()}>{addStatistic()}</div> */}

      </header>

      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden pb-4 ">
        <table
          {...getTableProps()}
          className="w-full h-full"
          variant="simple"
          color="gray-500"
          mb="24px"
        >
          <thead className="mb-2.5">
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border-b border-gray-200 pr-16 pb-[10px] text-start dark:!border-navy-700"
                    key={index}
                  >
                    <div className="text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {tlData[0] ? <tbody {...getTableBodyProps()} id="ubody">
            {page.map((row, index) => {
              // console.log(page,row,"page")
              prepareRow(row);
      
              return (
                <tr {...row.getRowProps()} key={index} id="trhight" >
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "User name") {
                      data = (
                        <div className="flex items-center gap-2">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === "Email") {
                      data = (
                        <div className="flex items-center">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === "Role") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {" "}
                          {cell.value}{" "}
                        </p>
                      );
                    } else if (cell.column.Header === "Title") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    }
                    else if (cell.column.Header === "Mobile") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    }
                    else if (cell.column.Header === "Company") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="pt-[5px] pb-[5px] sm:text-[14px]	"
                      >
                        {data}
                      </td>
                    );
                  })}
                 <div id="TR" className="usersTR"> </div>
                </tr>
              );
            })}
           <div id="dbody"></div>
          </tbody> :  <tbody> <tr><td className="w-[5px] h-[5px]"> 
            {isLoading ? <div class="loader"></div> : <h2 className="noDataFound">No Data Available</h2>}
          </td></tr> </tbody>}
        </table>
    {  //************************************************************

// ***************************** Add and Edit modal *****************************
}
        {/* { isOpen ?
        <div class="modal">
        <div id="popup">
          <button id="popIcon" onClick={()=>{setIsOpen(false)}}>
            <FaRegWindowClose className="w-full h-full text-blue-700	"/>
            </button>
                  <Formik
                      initialValues={ !editData?
                        {market: "", industry: '', microTarget: '', CPM: "", CTR: '',
                      purchaseROAS: '', CTRall: '', saturationPoint: ''}
                      :{market: editData.market ? editData.market :"" ,
                       industry: editData.industry ? editData.industry : "",
                       microTarget: editData.microTarget ? editData.microTarget: "", 
                       CPM: editData.CPM ? editData.CPM : "", 
                       CTR: editData.CTR ? editData.CTR : "",
                        purchaseROAS: editData.purchaseROAS ? editData.purchaseROAS : "", 
                        CTRall: editData.CTRall ? editData.CTRall : "", 
                        saturationPoint: editData.saturationPoint ? editData.saturationPoint : ""
                    }} 
                      validate={values => {
                          const errors = {};
                          if (!values.market) {
                              errors.market = 'Market is required';
                          }
                          else if (!values.industry) {
                              errors.industry = 'Industry is required';
                          }
                        else if (!values.microTarget) {
                          errors.microTarget = 'Micro Target is required';
                      }
                      else if (!values.CPM) {
                        errors.CPM = 'CPM is required';
                    }
                    else if (!values.CTR) {
                      errors.CTR = 'CTR is required';
                  }
                  else if (!values.purchaseROAS) {
                    errors.purchaseROAS = 'Purchase ROAS is required';
                }
                else if (!values.CTRall) {
                  errors.CTRall = 'CTR all is required';
              }
              else if (!values.saturationPoint) {
                errors.saturationPoint = 'Saturation Point is required';
            }
                 return errors; 
                      }}

                      onSubmit={(values,{setSubmitting}) => {
                        if(!editData){
                          api.post('http://localhost:3001/statistics', {
                              market: values.market,
                              industry: values.industry,
                              microTarget: values.microTarget,
                              CPM: values.CPM,
                              CTR: values.CTR,
                              purchaseROAS: values.purchaseROAS,
                              CTRall: values.CTRall,
                              saturationPoint: values.saturationPoint,
                          }).then(function (response) {
                            api.get("http://localhost:3001/statistics/")
                            .then(function (resq) {
                              setIsOpen(false);
                              setTlData(resq.data)
            
                            }).catch(error => {
                            });

                          }).catch(error => {
                            errorM();
                            setSubmitting(false);
                          });
                        }
                        else if(editData){
                          api.patch(`http://localhost:3001/statistics/${editData.id}`, {
                              market: values.market,
                              industry: values.industry,
                              microTarget: values.microTarget,
                              CPM: values.CPM,
                              CTR: values.CTR,
                              purchaseROAS: values.purchaseROAS,
                              CTRall: values.CTRall,
                              saturationPoint: values.saturationPoint,
                          }).then(function (response) {
                            setIsOpen(false);
                            success();
                            api.get("http://localhost:3001/statistics/")
                            .then(function (resq) {
                              setTlData(resq.data)
            
                              // window.location.reload();
                            }).catch(error => {
                            });
                
                            // setTlData(response.data)
                          }).catch(error => {
                            errorM();
                            setSubmitting(false);
                          });
                        }
                        }
                      }
                  >
                      {({ isSubmitting }) => (
                          <Form className={`flex items-center justify-center flex-col w-full`}>
                              <label id="label" className={`flex items-center justify-start flex-row w-full text-sm`} >Market</label>
                              <Field type="text" placeholder="Market" name="market" id="market" className={` flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none`} />
                              <ErrorMessage name="market" component="div" id="erroMassage" />
                              
                              <label id="label" className={`flex items-center justify-start flex-row w-full mt-2 text-sm`}>Industry</label>
                              <Field type="text" placeholder="Industry" name="industry" id="industry" className={`flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none `} />
                              <ErrorMessage name="industry" component="div" id="erroMassage" />
                             
                              <label id="label" className={`flex items-center justify-start flex-row w-full mt-2 text-sm`}>Micro-Target</label>
                              <Field type="text" placeholder="Micro-Target" name="microTarget" id="microTarget" className={` flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none `} />
                              <ErrorMessage name="microTarget" component="div" id="erroMassage" />
                             
                              <label id="label" className={`flex items-center justify-start flex-row w-full mt-2 text-sm`}>CPM</label>
                              <Field type="number" placeholder="CPM" name="CPM" id="CPM" className={` flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none `} />
                              <ErrorMessage name="CPM" component="div" id="erroMassage" />
                              
                              <label id="label" className={`flex items-center justify-start flex-row w-full mt-2 text-sm`}>CTR</label>
                              <Field type="number" placeholder="CTR" name="CTR" id="CTR" className={` flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none `} />
                              <ErrorMessage name="CTR" component="div" id="erroMassage" />
                             
                              <label id="label" className={`flex items-center justify-start flex-row w-full mt-2 text-sm`}>Purchase ROAS</label>
                              <Field type="number" placeholder="Purchase ROAS" name="purchaseROAS" id="purchaseROAS" className={`flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none `} />
                              <ErrorMessage name="purchaseROAS" component="div" id="erroMassage" />
                             
                              <label id="label" className={`flex items-center justify-start flex-row w-full mt-2 text-sm`}>CTR All</label>
                              <Field type="number" placeholder="CTR All" name="CTRall" id="CTRall" className={`flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none `} />
                              <ErrorMessage name="CTRall" component="div" id="erroMassage" />
                             
                              <label id="label" className={`flex items-center justify-start flex-row w-full mt-2 text-sm`}>Saturation Point</label>
                              <Field type="number" placeholder="Saturation Point" name="saturationPoint" id="saturationPoint" className={` flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none `} />
                              <ErrorMessage name="saturationPoint" component="div" id="erroMassage" />
                              
                              <button type="submit" disabled={isSubmitting} className=" flex items-center justify-center linear mt-2 w-40 h-10 rounded-xl bg-blue-700 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                                  {editData ? "Edit": 'Add'}
                              </button>
                              
                          </Form>
                      )}
                  </Formik>
              </div> </div> : <></>
              } */}
              {/* { delOpen ? <div id="delPopup">
                  <button id="popIcon" onClick={()=>{setDelOpen(false)}}>
            <FaRegWindowClose className="w-full h-full text-blue-700	"/>
            </button>
                <div  id="delText"><span >Are you sure want to delete this data</span></div>
                <div id="yesno">
                <button className="yesorno" onClick={()=>{setDelOpen(false)}}> No </button> 
                <button className="yesorno" onClick={() => {deleteData(id)
                  setDelOpen(false)}
                }> Yes </button>  </div>
              </div> : <></>

              } */}
      </div>
      { //************************************************************
//***************************** table Pagination *****************************
}
        <div id="devbody">
           <button className={`mr-1 flex items-center text-xl hover:cursor-pointer bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10
              } linear justify-center rounded-lg font-bold transition duration-200 MDbtnmag`} onClick={() => gotoPage(0)} disabled={!canPreviousPage}> 
         <MdFirstPage/>
        </button>
        <button className={`mr-1 flex items-center text-xl hover:cursor-pointer bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10
              } linear justify-center rounded-lg font-bold transition duration-200 MDbtnmag`} onClick={() => previousPage()} disabled={!canPreviousPage}>
        <MdNavigateBefore/>
        </button>
        <button className={`mr-1 flex items-center text-xl hover:cursor-pointer bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10
              } linear justify-center rounded-lg font-bold transition duration-200 MDbtnmag`} onClick={() => nextPage()} disabled={!canNextPage}>
          <MdNavigateNext/>
        </button>
        <button className={`mr-1 flex items-center text-xl hover:cursor-pointer bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10
              } linear justify-center rounded-lg font-bold transition duration-200 MDbtnmag`} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        <MdLastPage/>
        </button>
        <span className=" mr-1 ml-2 pt-2 h-full MDpageMag">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span className=" mr-1 leading-loose	h-full MDpageMag">
          | Go to page:
          <input
          className="rounded border-2	ml-2 h-full p-1 MDinpMag"
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
        </span>{" "}
        {/* <select className="rounded border-2	ml-2 h-full MDpageSize"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 15].map((pageSize) => (
            <option key={pageSize} value={pageSize} select >
              Show {pageSize}
            </option>
          ))}
        </select> */}
           </div>
    </Card>
    </div>

);
};

export default Tables;
