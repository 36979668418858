import React, { useMemo, useState, useEffect } from "react";
import Card from "components/card";
import "../../../../../src/assets/css/App.css"
import { MdLastPage } from "react-icons/md";
import { MdFirstPage } from "react-icons/md";
import { MdNavigateNext } from "react-icons/md";
import { MdNavigateBefore } from "react-icons/md";
import api from "views/auth/Api";
import { requestsData } from "../variables/requestsData";
import Dropdown from "components/dropdown"
import { BsThreeDots } from "react-icons/bs";
import { API_BASE_URL } from '../../../../configs/AppConfig';

import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";


const UesersRequests = () => {
    const [open, setOpen] = useState(false);
    const [tlData, setTlData] = useState([]);
    const [id , setId] = useState("");
    const [status , setStatus] = useState("");
    const [pageNum , setPageNum] = useState();
    const [isLoading , setIsLoading] = useState(false);

    // const [openChecked , setOpenChecked]= useState("");
    // const [closeChecked , setCloseChecked]= useState("");



    const getData = async () => {
        setIsLoading(true);
        api.get(`${API_BASE_URL}requests`)
            .then(function (response) {
                setTlData(response.data)
                setIsLoading(false);
                
            }).catch(error => {
                localStorage.clear()
                window.location.reload();
            });
    }
    useEffect(() => { getData() }, []);
    const columnsData = requestsData;
    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tlData, [tlData]);
   
    const rowId =(id)=>{
        setId(id) ;
    } 
    const rowStatus =(status)=>{
        setStatus(status);
    }
    const statisticsButton= (id )=>{
        // const { id } = props;
        // const addStatistic = (id) => {
        //   return (
        //     <AddStatistic id={id} />
        //   )
        // }
        // const editStatistic= (id)=> {
        //   if (!isOpen) {
        //     setIsOpen(true)
        // }      
        //   const ddata = [...tlData]
        //   const perData = ddata.filter((per) => per.id ===id)
        //   setEditData(perData[0]);
        // }


        const changeToOpen = (id)=>{
        api.patch(`${API_BASE_URL}requests/${id}`, {
            status:'Open',
        }).then(function (response) {
          setOpen(false);
          setTlData(response.data)
        }).catch(error => {
          console.log("handlesubmit error for blogggggggggg ", error)
        });
    }

    const changeToClose = (id)=>{
        api.patch(`${API_BASE_URL}requests/${id}`, {
            status:'Close',
        }).then(function (response) {
          setOpen(false);
          setTlData(response.data);
        }).catch(error => {
          console.log("handlesubmit error for blogggggggggg ", error)
        });
    }

        // **********************************************************
    
        // ***************************** Dropdown edit and  delete *****************************
    
        return (
          <Dropdown
            button={
              <button
                onClick={() => {setOpen(!open);
                }}
                //   setDelOpen(false);
                //   setIsOpen(false);
                // } }
                open={open}
                className={` flex items-center text-xl hover:cursor-pointer bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10
                   linear justify-center rounded-lg font-bold transition duration-200 `}
              >
                
                <BsThreeDots className="h-4 w-4" />
              </button>
            }
            animation={"origin-top-left transition-all duration-300 ease-in-out"}
            classNames="top-8 w-max dropdownErro "
            children={
              <div className= {`z-50 w-max rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none`} >
                {/* ${open ? "scale-100" : "hidden"} */}
                <p className=" flex cursor-pointer items-center gap-2 text-gray-600 hover:font-medium hoverEdit" onClick={() => changeToOpen(id)
                }>
                {/* <input type="radio" id="Open" name="Open" value="Open" className="statusCh" checked={openChecked}/> */}
                  Open
                </p>
                
                 <p className=" mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium hoverEdit" onClick={()=>{changeToClose(id)     
                }}>
             {/* {onClick={() => deleteData(id)} } */}
             {/* <input type="radio" id="Close" name="Close" value="Close" className="statusCh" checked={closeChecked}/> */}
                  Close
                </p>           
              </div>
            }
          />
        );
      }
    

    // **********************************************************

    // ***************************** useTable *****************************

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 15, pageIndex:pageNum }
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        // initialState,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        // setGlobalFilter,
        state: { pageIndex }

    } = tableInstance;
    // initialState.pageIndex=pageNum;
      // initialState.pageSize = 15;   

    //************************************************************

    // ***************************** return Table *****************************

    return (
        <div id="reqsTable">
            <Card extra={"w-full h-full sm:overflow-auto px-6 "}>
                <header className="relative flex items-center justify-between pt-4 h-14	">
                    <div className="text-xl font-bold text-navy-700 dark:text-white w-auto	flex flex-row justify-center items-center">
                        <span className="mr-5 MDtitle"> Requests </span>

                    </div>

                    {/* <CardMenu /> */}
                    {/* <div id="addStatistic" onClick={() => addOne()}>{addStatistic()}</div> */}

                </header>

                <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden pb-[52px]">
                    <table
                        {...getTableProps()}
                        className="w-full h-full"
                        variant="simple"
                        color="gray-500"
                        mb="24px"
                    >
                        <thead className="mb-2.5">
                            {headerGroups.map((headerGroup, index) => (
                                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                    {headerGroup.headers.map((column, index) => (
                                        <th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            className="border-b border-gray-200 pr-16 pb-[10px] text-start dark:!border-navy-700"
                                            key={index}
                                        >
                                            <div className="text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                                                {column.render("Header")}
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        {tlData[0] ? <tbody {...getTableBodyProps()} id="rbody">
                            {page.map((row, index) => {
                                prepareRow(row);

                                return (
                                    <tr {...row.getRowProps()} key={index} id="trhight" >
                                        {row.cells.map((cell, index) => {
                                            let data = "";
                                            if (cell.column.Header === "User name") {
                                                data = (
                                                    <div className="flex items-center gap-2">
                                                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                            {cell.value}
                                                        </p>
                                                    </div>
                                                );
                                            } else if (cell.column.Header === "Email") {
                                                data = (
                                                    <div className="flex items-center">
                                                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                            {cell.value}
                                                        </p>
                                                    </div>
                                                );
                                            }
                                            else if (cell.column.Header === "Mobile") {
                                                data = (
                                                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                        {cell.value}
                                                    </p>
                                                );
                                            }
                                            else if (cell.column.Header === "Status") {
                                                data = (
                                                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                        {cell.value}
                                                    </p>
                                                );
                                            }
                                           else if (cell.column.Header === "Date") {
                                            data = (
                                                <div className="flex items-center">
                                                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                        {cell.value}
                                                    </p>
                                                </div>
                                            );
                                        }

                                            
                                            return (
                                                <td
                                                    {...cell.getCellProps()}
                                                    key={index}
                                                    className="pt-[5px] pb-[5px] sm:text-[14px]	"
                                                >
                                                    {data}
                                                </td>
                                            );
                                        })}
                                        <div id="TR" onClick={()=> {rowId(row.original.id)
                                        rowStatus(row.original.status)
                                    setPageNum(pageIndex)}
                                        } >{statisticsButton(id , status)} </div>
                                    </tr>
                                );
                            })}
                            <div id="dbody"></div>
                        </tbody>: <tbody> <tr><td className="w-[5px] h-[5px]"> 
            {isLoading ? <div class="loader"></div> : <h2 className="noDataFound">No Data Available</h2>}
          </td></tr> </tbody>}
                    </table>
                </div>
                        { //************************************************************
                                //***************************** table Pagination *****************************
                            }
                    <div id="devbody">
                            <button className={`mr-1 flex items-center text-xl hover:cursor-pointer bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10
              } linear justify-center rounded-lg font-bold transition duration-200 MDbtnmag`} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                <MdFirstPage />
                            </button>
                            <button className={`mr-1 flex items-center text-xl hover:cursor-pointer bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10
              } linear justify-center rounded-lg font-bold transition duration-200 MDbtnmag`} onClick={() => previousPage()} disabled={!canPreviousPage}>
                                <MdNavigateBefore />
                            </button>
                            <button className={`mr-1 flex items-center text-xl hover:cursor-pointer bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10
              } linear justify-center rounded-lg font-bold transition duration-200 MDbtnmag`} onClick={() => nextPage()} disabled={!canNextPage}>
                                <MdNavigateNext />
                            </button>
                            <button className={`mr-1 flex items-center text-xl hover:cursor-pointer bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10
              } linear justify-center rounded-lg font-bold transition duration-200 MDbtnmag`} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                <MdLastPage />
                            </button>
                           

                            <span className=" mr-1 ml-2 pt-2 h-full MDpageMag">
                                Page{" "}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{" "}
                            </span>
                            <span className=" mr-1 leading-loose	h-full MDpageMag">
                                | Go to page:
                                <input
                                    className="rounded border-2	ml-2 h-full p-1 MDinpMag"
                                    type="number"
                                    defaultValue={pageIndex + 1}
                                    onChange={(e) => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                        gotoPage(page);
                                    }}
                                />
                            </span>{" "}
                            {/* <select className="rounded border-2	ml-2 h-full MDpageSize "
                                value={pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                }}
                            >
                                {[10, 15].map((pageSize) => (
                                    <option key={pageSize} value={pageSize} select >
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select> */}
                        </div>
            </Card>
        </div>
    );
};

export default UesersRequests;
